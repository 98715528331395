import * as React from 'react';
import { Outlet, Link, useNavigate } from "react-router-dom";
import './../index.css';
import ThemeImages from '../components/ThemeImages';
import Constants from '../../utilities/Constants';

let images = Constants.pages.about.images;

const Contact = () => {  
    const [loading, setLoading] = React.useState(true);

    return (
        <>
        <section className='odd p-5'>
            <h1 className='text-center m-3'>
                {Constants.pages.contact.title}
            </h1>
            
            <div className='row'>
                <div className='col-sm-6'>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3770.4693861142455!2d72.901968!3d19.087053399999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c62b298cdcf3%3A0xbba00b0b3e364f02!2sSaurabh%20Building%2C%20Mody%20Estates%2C%20Lal%20Bahadur%20Shastri%20Marg%2C%20Ghatkopar%20West%2C%20Mumbai%2C%20Maharashtra%20400086!5e0!3m2!1sen!2sin!4v1714211179727!5m2!1sen!2sin" width="100%" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <div className='col-sm-6'>
                    <div className='row justify-content-end'>
                    {
                        Constants.addresses.map(e => <div class="card m-1 col-sm-5">
                                                        <div class="card-body">
                                                            <i className={e.icon}></i>
                                                            {' '}
                                                            {e.text}
                                                        </div>
                                                    </div>
                                                )
                    }
                    {
                        Constants.emails.map(e => <div class="card m-1 col-sm-5">
                                                        <div class="card-body">
                                                            <i className={e.icon}></i>
                                                            <a href={`mailto:${e.email}`} >
                                                                {' '}
                                                                {e.email}
                                                            </a>
                                                        </div>
                                                    </div>
                                                )
                    }
                    {
                        Constants.contacts.map(e => <div class="card m-1 col-sm-5">
                                                        <div class="card-body">
                                                            <i className={e.icon}></i>
                                                            <a href={`tel:${e.number}`} >
                                                                {' '}
                                                                {e.number}
                                                            </a>
                                                        </div>
                                                    </div>
                                                )
                    }
                    </div>
                </div>
            </div>
        
        </section>
            
            
        </>
            
    )
}

export default Contact;
