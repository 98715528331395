import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';
import Constants from '../../utilities/Constants';

function ThemeNavbar() {
  return (
    <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary nav-brand px-3">
      {/* <Container> */}
        <Navbar.Brand >
          <Link to="/">
            <img src='logo.jpg' />
          </Link>
        </Navbar.Brand>
        
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
            <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                {Constants.menu.map(
                  e => 
                  e.type == 'external' ?
                  <li class="nav-item">
                    <a class="nav-link text-white" aria-current="page" href={e.link} target="_blank">
                      {e.text}
                    </a>
                  </li> :
                  <li class="nav-item">
                    <Link class="nav-link text-white" aria-current="page" to={e.link} >
                      {e.text}
                    </Link>
                  </li>
                )}
            </ul>

          </Nav>
        </Navbar.Collapse>
      {/* </Container> */}
    </Navbar>
  );
}

export default ThemeNavbar;