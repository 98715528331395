import React from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import Constants from "../utilities/Constants";
import ThemeNavbar from "./components/ThemeNavbar";
import "./index.css";
import ThemeInfoStrip from "./components/ThemeInfoStrip";
import ThemeFooter from "./components/ThemeFooter";

const Layout = () => {

  const [lang, setLang] = React.useState(Constants.selectedLanguage)

  Constants.navigate = useNavigate();

  const handleChange = (e) =>{
    Constants.selectedLanguage = e.target.value
    setLang(e.target.value)
  }

  return (
    <>
          <header>
              {/* <ThemeInfoStrip /> */}
              <ThemeNavbar />
           </header>
        
              <Outlet />
            
            <ThemeFooter />
            
    </>
  )
};

export default Layout;