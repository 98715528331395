const Constants = {
    menu: [
        {
            text: 'Home',
            link: '/',
            type: 'internal',
            icon: 'fa-solid fa-house'
        },
        {
            text: 'About Shivajirao',
            link: '/about',
            type: 'internal',
            icon: 'fa-solid fa-address-card'
        },
        {
            text: 'Voter search',
            link: 'http://votersearch.shivajiraonalawade.com/',
            type: 'external',
            icon: 'fa-solid fa-magnifying-glass'
        },
        {
            text: 'Contact Us',
            link: '/contact',
            type: 'internal',
            icon: 'fa-solid fa-address-book'
        },
    ],
    externalLinks: [
        // {
        //     text: 'The party',
        //     link: 'https://ncpmumbai.com/',
        //     icon: 'fa-solid fa-users'
        // },
        {
            text: 'Voter search',
            link: 'http://votersearch.shivajiraonalawade.com/',
            icon: 'fa-solid fa-magnifying-glass'
        },
        // {
        //     text: 'Join us',
        //     link: 'https://www.bjp.org/en/jointheparty',
        //     icon: 'fa-solid fa-plus'
        // }
    ],
    social: [
        {
            text: 'Twitter',
            link: 'twitter.com',
            icon: 'fa-brands fa-twitter'
        },
        {
            text: 'Facebook',
            link: 'facebook.com',
            icon: 'fa-brands fa-facebook'
        },
        {
            text: 'Instagram',
            link: 'instagram.com',
            icon: 'fa-brands fa-square-instagram'
        },
        {
            text: 'YouTube',
            link: 'youtube.com',
            icon: 'fa-brands fa-youtube'
        },
    ],
    addresses: [
        {
            text: '112, 113, Saurabh Building, LBS Road, Ghatkopar, Mumbai - 86',
            icon: 'fa-solid fa-location-dot'
        },
        {
            text: 'A-5, Pratapgad, Madam Cama Road, Mumbai - 32',
            icon: 'fa-solid fa-location-dot'
        }
    ],
    emails: [
        {
            email: 'shivajinalawade2022@gmail.com',
            icon: 'fa-solid fa-envelope'
        },
        // {
        //     email: 'name1@email.com',
        //     icon: 'fa-solid fa-envelope'
        // }
    ],
    contacts: [
        {
            number: '+91 9820280666',
            icon: 'fa-solid fa-mobile'
        },
        // {
        //     number: '+91 99999999999',
        //     icon: 'fa-solid fa-mobile'
        // },
        {
            number: '+91 9820280666',
            icon: 'fa-brands fa-square-whatsapp'
        },
    ],
    pages: {
        about: {
            title: 'About Shivajirao',
            link: '/about',
            images: [
                'https://source.unsplash.com/1600x1200?female,portrait',
                'https://source.unsplash.com/1024x768?female,portrait',
                'https://source.unsplash.com/1366x768?female,portrait',
                'https://source.unsplash.com/1920x1080?female,portrait',
                'https://source.unsplash.com/640x360?female,portrait',
                'https://source.unsplash.com/320x640?female,portrait',
                'https://source.unsplash.com/1200x1600?female,portrait',
                'https://source.unsplash.com/800x600?female,portrait',
                'https://source.unsplash.com/600x800?female,portrait',
                'https://source.unsplash.com/400x600?female,portrait',
                'https://source.unsplash.com/600x400?female,portrait',
                'https://source.unsplash.com/1100x1600?female,portrait',
                'https://source.unsplash.com/1600x1100?female,portrait',
                'https://source.unsplash.com/992x768?female,portrait',
                'https://source.unsplash.com/768x992?female,portrait',
            ]
        },
        contact: {
            title: 'Contact Us',
            link: '/contact',
            images: [
                
            ]
        }
    }
}

export default Constants;