import React from 'react';

function ThemeImages({images}) {

  const [selectedImg, setSelectedImg] = React.useState('')
  const [open, setOpen] = React.useState(false)

  return (
    images.length ? 
  <>
    <div id="gallery" className="container-fluid">  
                {
                    images.map(
                        i => <img 
                            src={i} 
                            className="img-responsive"
                            onClick={
                                (e) => {
                                    setSelectedImg(e.target.getAttribute('src'))
                                    setOpen(true)
                                }
                            }
                    />
                    )
                }
            </div>

            {open && <div id="myModal" className="modal" role="dialog" onClick={() => { 
            setSelectedImg('');
            setOpen(false) }}>
                <div className="modal-dialog" >
                    <div className="modal-content">
                        <div className="modal-body">
                            <img src={selectedImg} className='modal-img' />
                        </div>
                    </div>

                </div>
            </div>}
            </> : <></>
  );
}

export default ThemeImages;