import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Layout from './ui/Layout';
import Home from "./ui/pages/Home";
import Notfound from "./ui/Notfound";
import 'bootstrap/dist/css/bootstrap.min.css';
import About from "./ui/pages/About";
import Constants from "./utilities/Constants";
import Contact from "./ui/pages/Contact";
// import Errors from "./ui/Error";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path={Constants.pages.about.link} element={<About />} />
          <Route path={Constants.pages.contact.link} element={<Contact />} />
          <Route path="*" element={<Notfound />} />
        </Route>
    </Routes>
  </BrowserRouter>
  );
}

export default App;
