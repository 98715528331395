import * as React from 'react';
import { Outlet, Link, useNavigate } from "react-router-dom";
import './../index.css';
import ThemeImages from '../components/ThemeImages';
import Constants from '../../utilities/Constants';
import Image from 'react-bootstrap/Image';


let images = Constants.pages.about.images;

const About = () => {  
    const [loading, setLoading] = React.useState(true);

    return (
        <>
            <h1 className='text-center m-3'>
                {Constants.pages.about.title}
            </h1>
            <div className='row container-fluid p-3 px-5'>
                <div className='col-sm-8'>
                He is married to Sarita Nalawade with three children. Their elder daughter, Varsha, is a doctor and the elder son, Raja is a Financial Advisor. Whereas the youngest son, Avinash, is a sous chef turned hotelier.
                </div>
                <div className='col-sm-4'>
                    <Image src='Nalawade_Saheb_Photo.jpg' alt='Shivajirao Nalawade' thumbnail />
                </div>
            </div>

            <section className='p-3 px-5 odd'>
                <h2 className='text-center m-5'>
                    Early life
                </h2>
                <p>
                    Shiwajirao Vishnu Nalawade was born on 1 June 1954 to Vishnu Genuji Nalawade and Vithabai Vishnu Nalawade. His father's name is Shri. Vishnu Genuji Nalawade, who was an employee of Firestone Rubber Company in Mumbai. His mother Smt.Vithabai, on the other hand, looked after the family farm at Dholwad, Taluka, Junnar District, Pune in Maharashtra State, India.
                </p>
                <p>
                    Mr. Nalawade completed his school from Shivneri Vidyalay Dholwad. He, then, pursued his university studies at the Ramniranjan Jhunjhunwala Night College, Arts faculty, in Ghatkopar-West, Mumbai. Since young, he was already very active in student movements and politics.
                </p>
            </section>

            <section className='p-3 px-5 '>
                <h2 className='text-center m-5'>
                    Career
                </h2>
                <h3 className=''>
                    Leadership at trade union
                </h3>
                <p>
                    In 1972, Mr. Nalawade started his career in Larsen & Toubro, Powai, Mumbai as a helper to Clerk. There, he got involved with trade union activities as a leader. He started from a shop committee member and reached to General Position Secretary. He was leading over more than 7000 workers, under the flag of "Bharatiya Kamgar Sena Union" until 1981. Thereafter, he formed his own workers' union by the name "Kamgar Kranti Sanghatan Union", which was mainly operational in un-organized labour sector.
                </p>
                <h3 className=''>
                    Leadership in co-operatives
                </h3>
                <p>
                    In 1993, he was elected as a Director at Mumbai District Central Co-operative Bank Ltd Mumbai., also well known as Mumbai Bank. Representing the Urban Co-operative Credit Societies Constituency, Mumbai Bank has a turnover of Rs. 5500 Crores. During this tenure, he was elected as a Chairman of the bank, three times. In fact, he has been leading Brihanmumbai Nagari Sahakari Patsanstha Federation Limited, Mumbai as a chairman since 30 March 2003 to channelize the co-operative movement through Urban Co-operative Credit Societies. He also started a monthly co-operative magazine named "Patsanstha Pariwar" on 13 November 2000, the same date as the birth Anniversary of Co-operative Leader and Ex-Chief Minister of Maharashtra, Sahakar Maharshi, late Shri. Vasantdada Patil. The magazine, which covers all types of important information about the latest updates and developments in co-operative movement, is established to raise the voice of co-operative workers and activist in Maharashtra, and is available all across Maharashtra.                
                </p>
                <h3 className=''>
                    Free "Mediclaim" policy project for citizens
                </h3>
                <p>
                    He was very keen in making healthcare facilities available to citizens of Maharashtra. In the year 2008, he introduced a healthcare plan to the Ministers of Maharashtra State by implementing a "Group Mediclaim" policy to all the families in Maharashtra State. After getting a negative response from them, he himself implemented his proposal for the needy 22,450 families in Ghatkopar. His project covered more than One Lakh people, having a sum assured of Rs. 50,000/- per family, through Oriental Insurance Company. In fact, hundreds of families took benefit of this "Group Mediclaim" Policy.                
                </p>
                <h3 className=''>
                    First ever generic medical shop
                </h3>
                <p>
                    He was in search of the right healthcare facilities to be provided to the citizens. With the information he gathered, he opened the first ever generic medicine shop named as "Janadhar Medical and General Stores" in Ghatkopar (West), Maharashtra, which is run and managed under his flagship NGO Janadhar Pratishthan. The shop was inaugurated on 14 January 2013. People become enthusiastic since Janadhar Medical and General Stores is selling most of the common generic medicines manufactured by well known companies like Cipla, Lupin, and Dr. Reddy's among others. More importantly, the medicines are up to 85% less compared to other medical shops. He said that the secret behind this drastic cost reduction lies in honestly, passing the purchase cost to the customers after only adding the management cost of the shop.                
                </p>
            </section>

            <section className='p-3 px-5 odd'>
                <h2 className='text-center m-5'>
                    Political career and achievements
                </h2>
                <ul>
                    <li>
                        Mr. Nalawade started his political career in 1977.
                    </li>
                    <li>
                        He was a Secretary of Mumbai Pradesh Youth Congress Committee(s).
                    </li>
                    <li>
                        He worked as a general secretary for Mumbai North East Congress Committee.
                    </li>
                    <li>
                        A first grand meeting and rally was organised by Mr. Nalawade on 23 May 1999 at Yashwantrao Chavan Pratishthan, Mumbai, to support the establishment of Nationalist Congress Party on 10 June 1999.
                    </li>
                    <li>
                        He was the Founder President of Mumbai North East District Nationalist Congress Party for 3 years from the establishment of NCP.
                    </li>
                    <li>
                        It was in his command that the highest ever 10 Municipal Counselor were elected from Mumbai North East District, whereas only 14 Municipal Counselor were elected all over Mumbai.
                    </li>
                    <li>
                        He played the main role in election campaigns of MP and MLA candidates of Congress and NCP.
                    </li>
                </ul>
            </section>

            <section className='p-3  d-none'>
                <h2 className='text-center m-5'>
                    Photos
                </h2>
                <ThemeImages images={images} />
            </section>
            
        </>
            
    )
}

export default About;
